<template>
	<div class="market-detail">
		<div class="breadcrumb" v-show="!isFullscreen">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>分析报告</el-breadcrumb-item>
				<el-breadcrumb-item to="/research/data-market" v-if="type == 3">数据超市</el-breadcrumb-item>
				<el-breadcrumb-item to="/research/new-market" v-if="type == 2">市场简报</el-breadcrumb-item>
				<el-breadcrumb-item to="/research/subdivide-market" v-if="type == 1">专题报告</el-breadcrumb-item>
				<el-breadcrumb-item v-if="type == 3">数据报告详情</el-breadcrumb-item>
				<el-breadcrumb-item v-if="type == 2">市场简报详情</el-breadcrumb-item>
				<el-breadcrumb-item v-if="type == 1">专题报告详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="detail-container">
			<div class="fie-title">
				<div class="file-name-box">
					<img class="file-img" :src="getFileUrl()">
					<el-tooltip effect="light" :content="detailData.reportTitle" placement="bottom-start">
						<div class="file-name text-ellipsis">
							{{ detailData.reportTitle }}
						</div>
					</el-tooltip>
				</div>
				<div class="collect-box" @click="toCollect">
					<span class="iconfont icon-shoucang" :style="{color: detailData.isCollection ? '#fca82f' : '#8E98AE'}"></span>
					<span>{{ detailData.isCollection ? "已" : "" }}收藏</span>
				</div>
				<!-- <div class="download-box" @click="toDownload" v-if="type == 2 && memberId != 2&&memberId != 5">
					<span class="iconfont icon-download"></span>
					<span>下载报告</span>
				</div> -->
				<DownloadButton type="专题分析报告" :id="detailData.reportId" :detailData="detailData" v-if="type == 1"/>
				<DownloadButton type="市场分析简报" :id="detailData.reportId" :detailData="detailData" @toPay="toPay" v-if="type == 2"/>
				<DownloadButton type="数据分析报告" :id="detailData.reportId" :detailData="detailData" @toPay="toPay" v-if="type == 3"/>
				<div class="download-box" @click="click" v-if="filePath">
					<span :class="isFullscreen ? 'iconfont icon-quxiaoquanping' : 'iconfont icon-quanping'"></span>
					<span>{{ isFullscreen ? "取消全屏" : "全屏预览" }}</span>
				</div>
			</div>
			<div class="content" :class="isFullscreen ? 'full-file-content' : 'file-content'" @scroll="scrollEvent">
				<div class="operation" v-show="isFullscreen">
					<img @dragstart.prevent src="@/assets/img/magnify.png" alt="" @click="toMagnify" v-show="scaleNum != 1" />
					<img @dragstart.prevent src="@/assets/img/magnify-stop.png" alt="" v-show="scaleNum == 1" style="cursor: not-allowed" />
					<div class="line"></div>
					<img @dragstart.prevent src="@/assets/img/shrink.png" alt="" @click="toShrink" v-show="scaleNum != -3" />
					<img @dragstart.prevent src="@/assets/img/shrink-stop.png" alt="" v-show="scaleNum == -3" style="cursor: not-allowed" />
					<div class="line"></div>
					<img @dragstart.prevent src="@/assets/img/refresh.png" alt="" @click="toRefresh" />
				</div>
				<!-- <div class="file-content"> -->
				<!-- 报告内容...... -->
				<!-- <pdf v-for="item in pageArray" :key="item" :src="src" :page="item"></pdf> -->
				<iframe :src="kkFileUrl + previewUrl" v-if="kkFileUrl && previewUrl" class="preview-iframe"></iframe>
			</div>
			<!-- <div class="masking-box" v-show="nextShow">
                <div class="text-box" @click="next()">
                <span class="member">继续阅读</span>
                </div>
            </div> -->
		</div>
		<!-- <div class="small-title-box" :style="!isFullscreen ? (recommendList.length != 0 ? 'display: block' : 'display: none') : 'display: none'">
			<div class="small-title">推荐阅读</div>
		</div>
		<div class="recommend-box" :style="!isFullscreen ? (recommendList.length != 0 ? 'display: block' : 'display: none') : 'display: none'">
			<el-row :gutter="20">
				<el-col :span="12" v-for="(item, i) in recommendList" :key="i">
					<div class="grid-content" @click="toDetail(item.reportId)">
						<img @dragstart.prevent :src="item.frontCoverImg" alt="" />
						<div class="right-box">
							<el-tooltip effect="light" :content="item.reportTitle" placement="bottom-start">
								<div class="title text-ellipsis-two">
									{{ item.reportTitle }}
								</div>
							</el-tooltip>
							<div class="content text-ellipsis-four">
								{{ item.reportDesc }}
							</div>
							<div class="price-box">
								<div v-if="item.readPrice != 0">
									订阅价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.readPrice }}</span>
									/期
								</div>
								<div v-if="item.readPrice == 0">
									订阅价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
								<div class="down-price" v-if="item.downloadPrice != 0">
									下载价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div class="down-price" v-if="item.downloadPrice == 0">
									下载价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
							</div>
							<div class="member">
								会员价格：
								<span>免费</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div> -->
		<pay-modal ref="payModal"></pay-modal>
		<ToPayOrMember ref="toPayOrMember" :text="text" :showPayBtn="showPayBtn" :type="typeName"></ToPayOrMember>
	</div>
</template>

<script>
import PayModal from "@/components/payModal";
import ToPayOrMember from "@/components/ToPayOrMember";
// import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import {Base64} from "js-base64";
// import screenfull from "screenfull";
// import pdf from "vue-pdf";
import {mapGetters} from "vuex";
import DownloadButton from "@/components/downloadButton";
import csv from "../../../assets/img/file/csv.png"
import doc from "../../../assets/img/file/doc.png"
import docx from "../../../assets/img/file/docx.png"
import jpg from "../../../assets/img/file/jpg.png"
import pdf from "../../../assets/img/file/pdf.png"
import png from "../../../assets/img/file/png.png"
import rar from "../../../assets/img/file/rar.png"
import xls from "../../../assets/img/file/xls.png"
import xlsx from "../../../assets/img/file/xlsx.png"
import zip from "../../../assets/img/file/zip.png"

export default {
	components: {
		PayModal,
		// pdf,
		ToPayOrMember,
		DownloadButton
	},
	data() {
		return {
			text: "",
			type: null, // 1 专题分析研究报告 2市场简报  3 数据分析报告
			detailData: {}, // 研报信息
			src: "", // 研报地址
			numPages: "", // 总页数
			times: 1,
			yushu: 1,
			pageArray: [],
			currentPage: 1,
			nextShow: false,
			reportId: "", // 研报id
			recommendList: [],
			visible: false,
			isFullscreen: false,
			scaleNum: 1, // 放大缩小基数
			filePath: "",
			previewUrl: "",
			showPayBtn: true,
			memberId: null,
			typeName: "",//付款类型名称
			fileType: 'pdf',
		};
	},
	computed: {
		...mapGetters(["kkFileUrl"]),
	},
	mounted() {
		let type = this.$route.query.type;
		this.type = type;
		if (type == 2) {
			this.typeName = "市场简报";
		} else if (type == 1) {
			this.typeName = "专题报告";
		} else if (type == 3) {
			this.typeName = "数据超市";
		}
		let reportId = this.$route.query.id;
		this.reportId = reportId;
		this.getReportInfo(reportId);
		this.getReportPdf(reportId, 1);
		this.getReportRecommended(type, reportId);
		this.memberId = this.$session.getUsers().memberId;
	},
	methods: {
		getFileUrl() {
			
			//  根据fileType获取不同的文件
			switch (this.fileType) {
				case 'csv':
					return csv;
				case 'doc':
					return doc;
				case 'docx':
					return docx;
				case 'jpg':
					return jpg;
				case 'pdf':
					return pdf;
				case 'png':
					return png;
				case 'rar':
					return rar;
				case 'xls':
					return xls;
				case 'xlsx':
					return xlsx;
				case 'zip':
					return zip;
				case '7z':
					return rar;
				case 'zip11':
					return rar;
				default:
					return pdf;
			}
		},
		scrollEvent(e) {
			if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight + 0.5) >= e.currentTarget.scrollHeight) {
				//容差：20px
				console.log("滚动到底部");
				if (this.currentPage == this.times - 1) {
					this.nextShow = false;
				}
				if (this.currentPage == this.times) {
					// this.nextShow = false;
					return;
				}
				if ((this.currentPage + 1) * 5 <= this.numPages) {
					for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
						this.pageArray.push(i);
					}
				} else {
					for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
						this.pageArray.push(i);
					}
				}
				this.currentPage++;
			}
		},
		// 点击放大
		toMagnify() {
			let {scaleNum} = this;
			scaleNum += 1;
			this.scaleNum = scaleNum;
			document.querySelector(".full-file-content").style.padding = `10px ${300 - (scaleNum - 1) * 50}px`;
		},
		// 点击缩小
		toShrink() {
			let {scaleNum} = this;
			scaleNum -= 1;
			this.scaleNum = scaleNum;
			document.querySelector(".full-file-content").style.padding = `10px ${300 - (scaleNum - 1) * 50}px`;
		},
		// 点击刷新
		toRefresh() {
			this.scaleNum = 1;
			document.querySelector(".full-file-content").style.padding = "10px 300px";
		},
		// 点击收藏（取消/收藏）
		toCollect() {
			let {detailData} = this;
			if (detailData.isCollection == 0) {
				// 收藏
				this.$api.personalCenter
					.collection({
						relationId: this.reportId,
						collectionType: 60611001,
					})
					.then(() => {
						this.$message.success("收藏成功");
						this.detailData.isCollection = 1;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 取消收藏
				this.$api.personalCenter
					.cancel({
						relationId: this.reportId,
						collectionType: 60611001,
					})
					.then(() => {
						this.$message.success("取消收藏成功");
						this.detailData.isCollection = 0;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 获取报告数据
		getReportInfo(reportId) {
			this.$api.research
				.getReportInfoApp({
					reportId,
				})
				.then(res => {
					this.detailData = res.data;
					console.log(this.detailData, "detailData");
					this.filePath = res.data.filePath;
					this.fileType = res.data.filePath.split(".").pop();
					this.previewUrl = "/onlinePreview?url=" + encodeURIComponent(Base64.encode(this.filePath));
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
					if (msg?.msg == "资源未找到") {
						setTimeout(() => {
							this.$router.push("/home");
						}, 2000);
					}
				});
		},
		// 获取报告文件数据
		getReportPdf(reportId, type) {
			// 1 预览 2 下载
			if (type == 1) {
				// const loading = this.$loading({
				// 	lock: true,
				// 	text: "加载中...",
				// 	spinner: "el-icon-loading",
				// 	background: "rgba(255, 255, 255, 0.3)",
				// 	fullscreen: false,
				// });
				// this.$api.research
				// 	.getReportPdf({
				// 		reportId,
				// 		type,
				// 	})
				// 	.then(res => {
				// 		if (res.type == "application/json") {
				// 			let that = this;
				// 			let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
				// 			reader.readAsText(res);
				// 			reader.onload = function (result) {
				// 				let resData = JSON.parse(result.target.result); // 解析对象成功，说明是json数据
				// 				loading.close();
				// 				if (resData.code == "10001111") {
				// 					that.$refs.toPayOrMember.openDialog();
				// 					that.text = resData?.msg;
				// 				} else {
				// 					that.$message.error(resData?.msg);
				// 				}
				// 			};
				// 		} else {
				// 			this.src = this.$utils.getObjectURL(res);
				// 			// 获取pdf页数
				// 			let src = pdf.createLoadingTask({
				// 				url: this.src,
				// 				CMapReaderFactory,
				// 			});
				// 			src.promise.then(pdf => {
				// 				console.log(pdf.numPages, "pdf.numPages");
				// 				this.numPages = pdf.numPages;
				// 				this.times = Number.parseInt(this.numPages / 5);
				// 				this.yushu = this.numPages % 5;
				// 				// if (this.yushu > 0 && this.times == 0) {
				// 				//   this.nextShow = false;
				// 				// } else if (this.yushu == 0 && this.times == 1) {
				// 				//   this.nextShow = false;
				// 				// } else {
				// 				//   this.nextShow = true;
				// 				// }
				// 				// if (this.yushu > 0) {
				// 				//   this.times++;
				// 				// }
				// 				// if (this.times == 0) {
				// 				//   for (let i = 1; i <= this.yushu; i++) {
				// 				//     this.pageArray.push(i);
				// 				//   }
				// 				// } else {
				// 				//   for (let i = 1; i <= 5; i++) {
				// 				//     this.pageArray.push(i);
				// 				//   }
				// 				// }
				// 				if (this.yushu > 0) {
				// 					this.times++;
				// 					if (this.times == 1) {
				// 						this.nextShow = false;
				// 						for (let i = 1; i <= this.yushu; i++) {
				// 							this.pageArray.push(i);
				// 						}
				// 					} else {
				// 						this.nextShow = true;
				// 						for (let i = 1; i <= 5; i++) {
				// 							this.pageArray.push(i);
				// 						}
				// 					}
				// 				} else if (this.yushu == 0) {
				// 					if (this.times == 1) {
				// 						this.nextShow = false;
				// 					} else {
				// 						this.nextShow = true;
				// 					}
				// 					for (let i = 1; i <= 5; i++) {
				// 						this.pageArray.push(i);
				// 					}
				// 				}
				// 			});
				// 		}
				// 	})
				// 	.catch(msg => {
				// 		if (msg?.msg) {
				// 			this.$message.error(msg?.msg);
				// 		}
				// 	})
				// 	.finally(() => {
				// 		loading.close();
				// 	});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				conaole.log(type, 'type');	
				this.$api.research
					.getReportPdf({
						reportId,
						type,
					})
					.then(res => {
						if (res.type == "application/json") {
							let that = this;
							let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
							reader.readAsText(res);
							reader.onload = function(result) {
								let resData = JSON.parse(result.target.result); // 解析对象成功，说明是json数据
								loading.close();
								if (resData.code == "10001111") {
									that.$refs.toPayOrMember.openDialog();
									that.text = resData?.msg;
								} else {
									that.$message.error(resData?.msg);
								}
							};
						} else {
    						const fileType = this.detailData.filePath.split('.').pop(); // 提取文件后缀
    						this.$utils.downloadFile(res, this.detailData.reportTitle + "." + fileType);
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 超出继续阅读
		next() {
			console.log(this.currentPage, this.times);
			if (this.currentPage == this.times - 1) {
				this.nextShow = false;
			}
			if (this.currentPage == this.times) {
				// this.nextShow = false;
				return;
			}
			if ((this.currentPage + 1) * 5 <= this.numPages) {
				for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
					this.pageArray.push(i);
				}
			} else {
				for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
					this.pageArray.push(i);
				}
			}
			this.currentPage++;
		},
		// 网页全屏方法
		click() {
			// if (!screenfull.isEnabled) {
			//   this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
			//   return false
			// }
			// screenfull.toggle()
			// if (this.isFullscreen) {
			//   this.toRefresh();
			//   this.isFullscreen = !this.isFullscreen;
			//   document.querySelector(".detail-container").style.width = "1200px";
			//   document.querySelector(".file-content").style.padding = "10px 200px";
			// } else {
			//   this.isFullscreen = !this.isFullscreen;
			//   document.querySelector(".detail-container").style.width = "100vw";
			// }

			let {filePath} = this;
			const fileKey = filePath.substr(filePath.lastIndexOf("/"));
			window.open(this.kkFileUrl + this.previewUrl, fileKey);
		},
		// 研报推荐阅读
		getReportRecommended(type, reportId) {
			this.$api.research
				.getReportRecommended({
					reportId,
					platform: 1,
					childModuleCode: type == 1 ? "10011001" : "10011002",
				})
				.then(res => {
					this.recommendList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击下载
		toDownload() {
			// this.$message.error("暂无下载权限");
			console.log('toDownload');
			this.$api.research
				.getReadAndDownload({
					reportId: this.reportId,
				})
				.then(res => {
					let data = res.data;
					if (data.download == "10011001") {
						// 有下载权限
						this.getReportPdf(this.reportId, 2);
					} else {
						// 无下载权限，提示购买下载
						this.toPay();
						// this.$refs.payModal.open = true;

						if (data.buyRead == "10011001") {
							// 购买了阅读
							this.$refs.payModal.init(2, this.detailData, "已购阅读");
						} else {
							this.$refs.payModal.init(2, this.detailData);
						}
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		toPay() { 
			this.$refs.toPayOrMember.orderData = { reportId: this.reportId,readOrDownLoad: "60491002",  reportPay: true};
			this.$refs.toPayOrMember.openDialog();
		},

		// 点击列表进入详情
		toDetail(reportId) {
			// 判断阅读，下载及会员情况
			let {type} = this;
			this.$api.research
				.getReadAndDownload({
					reportId,
				})
				.then(res => {
					let data = res.data;
					if (data.read == "10011001") {
						// 有阅读权限
						let url = this.$router.resolve({
							path: `/research/market-detail?type=${type}&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					} else {
						// 没有阅读权限
						let url = this.$router.resolve({
							path: `/research/market-preview?type=${type}&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
	},
};
</script>
<style lang="less">
@import "./index.less";
</style>
